import React from "react";
import { useState, useEffect } from "react";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import {
  getData,
  getComplianceData,
  setComplianceData,
  runMMCompliance,
  runMMComplReport,
  runMMComplSpreads,
} from "../services/user.service";

import { intervalCellRender, intervalSetter } from "../functions/time.function";
import { useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import moment from "moment/moment";
import "moment/locale/es";
moment.locale("es");

registerLocale("es", es);

function compByProduct(data) {
  let interim = data.reduce((map, obj) => {
    const prod = obj.product;
    if (!map.has(prod)) {
      map.set(prod, []);
    }

    map.get(prod).push(obj);

    return map;
  }, new Map());
  interim.forEach((array, key) => {
    array.sort((a, b) => new Date(a.dtime) - new Date(b.dtime));
    interim.set(key, array); // Update the map with the sorted array
  });
  return interim;
}

function monthsByMM(data) {
  return data.reduce((map, obj) => {
    const mm = obj.market_maker;

    if (!map.has(mm)) {
      map.set(mm, { months: new Map() });
    }

    map.get(mm).months.set(moment(obj.dtime).format("MMMM YYYY"), obj.dtime);

    return map;
  }, new Map());
}

const theme = createTheme({
  mixins: {
    MuiDataGrid: {
      containerBackground: "#06b6d4",
      columnHeaderTitle: {
        color: "white", // Specific targeting
        textAlign: "center",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "white", // Specific targeting
      },
    },
  },
});

function ComplianceReport() {
  const [mmdata, setMMdata] = useState(new Map());
  const [comData, setComData] = useState(new Map());
  const [editedComData, setEditComData] = useState(new Map());
  const [origComData, setOrigComData] = useState(new Map());
  const [updating, setUpdating] = useState("");
  const [selMM, setSelMM] = useState("");
  const [selMonth, setSelmonth] = useState("");
  const [months, setMonths] = useState(new Map());
  let location = useLocation();

  const refresh = () => {
    getData("cmplm")
      .then((res) => {
        let dbdata = JSON.parse(res.data).mmmonths;
        let organizedmmmonths = monthsByMM(dbdata);
        setMMdata(organizedmmmonths);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    refresh();
  }, [location]);

  const getCompData = (my_mm, month) => {
    getComplianceData(my_mm, month)
      .then((res) => {
        // first we create a map by product
        let compData = compByProduct(res.data);

        setComData(compData);
        setOrigComData(structuredClone(compData));
        setEditComData(new Map());
      })
      .catch((err) => console.log(err));
  };

  const LinkedDropdowns = () => {
    const handleMMChange = (event) => {
      const user = event.target.value;
      setSelMM(user);
      setSelmonth("");
      setComData(new Map());
      setOrigComData(new Map());

      if (mmdata.has(user)) {
        setMonths(mmdata.get(user).months);
      } else {
        setMonths(new Map());
      }
    };

    const handleMonthChange = (event) => {
      const month = event.target.value;
      setSelmonth(month);
      getCompData(selMM, months.get(month));
    };

    return (
      <div>
        <select
          id="user-select"
          value={selMM}
          onChange={handleMMChange}
          className=" py-2 px-10 mt-10 mx-10 sm:text-sm rounded-md"
        >
          <option value="">--Market Maker--</option>
          {[...mmdata.keys()].map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>

        <select
          id="month-select"
          disabled={!selMM}
          onChange={handleMonthChange}
          value={selMonth}
          className=" py-2 px-10 mt-10 mx-10 sm:text-sm rounded-md"
        >
          <option value="">--Mes de análisis--</option>
          {[...months?.keys()].map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const compareMaps = (original, currentData, changedData) => {
    const result = [];
    changedData.forEach((val, key) => {
      new Set(val).forEach((_v, k) => {
        let oriRow = original.get(key).find((x) => x.dtime === k);
        let nuRow = currentData.get(key).find((x) => x.dtime === k);
        if (oriRow && nuRow) {
          if (
            Object.keys(nuRow).some(
              (rowCol) => nuRow[rowCol] !== oriRow[rowCol]
            )
          ) {
            result.push(nuRow);
          }
        }
      });
    });

    return result;
  };

  const saveChanges = () => {
    let valueUpdater = compareMaps(origComData, comData, editedComData);
    if (valueUpdater.length > 0) {
      setUpdating(
        `Actualizando valores de cumplimiento a las ${moment().format(
          "HH:mm:SS"
        )}.`
      );
      setComplianceData(valueUpdater)
        .then((response) => {
          setUpdating(
            `Respuesta recibida a las ${moment().format("HH:mm:SS")}:\n${
              response.data
            }`
          );
          refresh();
        })
        .catch((error) => {
          console.log(
            error,
            error?.response?.data ? error?.response?.data : ""
          );
          setUpdating(
            `Error recibido a las ${moment().format(
              "HH:mm:SS"
            )} mientras se actualizaba:\n${error}${
              error?.response?.data
                ? `\nDetalle del error:${error?.response?.data}`
                : ""
            }`
          );
          // refresh();
        });
    } else {
      setUpdating("Nada que actualizar");
    }
  };

  const handleEjecutarCumplimiento = () => {
    runMMCompliance(selMM, months.get(selMonth))
      .then((response) => {
        setUpdating(
          `Ejecución de cumplimiento de ${selMM} para ${months.get(
            selMonth
          )} finalizado a las ${response.data.time}.\nEstado final: ${
            response.data.state === "success" ? "éxito" : "error"
          }.${
            response?.data?.returnvalue
              ? "\nRespuesta recibida: " + response?.data?.returnvalue + "."
              : ""
          }`
        );
      })
      .catch((error) => {
        console.log(error);
      });
    setUpdating(
      `Ejecución de cumplimiento de ${selMM} para ${months.get(
        selMonth
      )} iniciado a las ${moment().format("HH:mm:SS")}.`
    );
  };

  const handleGenerarInformes = () => {
    console.log(moment().format("HH:mm:SS"));
    runMMComplReport(selMM, months.get(selMonth))
      .then((response) => {
        setUpdating(
          `Generación de informes de cumplimiento de ${selMM} para ${months.get(
            selMonth
          )} finalizada a las ${response.data.time}.\nEstado final: ${
            response.data.state === "success" ? "éxito" : "error"
          }.${
            response?.data?.returnvalue
              ? "\nRespuesta recibida: " + response?.data?.returnvalue + "."
              : ""
          }`
        );
      })
      .catch((error) => {
        console.log(error);
      });
    setUpdating(
      `Generando informes de cumplimiento de ${selMM} para ${months.get(
        selMonth
      )} iniciada a las ${moment().format("HH:mm:SS")}.`
    );
  };

  const handleGenerarSpreads = () => {
    console.log(moment().format("HH:mm:SS"));
    runMMComplSpreads(selMM, months.get(selMonth))
      .then((response) => {
        setUpdating(
          `Generación de spreads de ${selMM} para ${months.get(
            selMonth
          )} finalizada a las ${response.data.time}.\nEstado final: ${
            response.data.state === "success" ? "éxito" : "error"
          }.${
            response?.data?.returnvalue
              ? "\nRespuesta recibida: " + response?.data?.returnvalue + "."
              : ""
          }`
        );
      })
      .catch((error) => {
        console.log(error);
      });
    setUpdating(
      `Generando spreads de cumplimiento de ${selMM} para ${months.get(
        selMonth
      )} iniciada a las ${moment().format("HH:mm:SS")}.`
    );
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log(error.message);
  }, []);

  const valSetter = (value, row, k) => {
    console.log(value);
    if (value === "") {
      value = null;
    }
    row[k.field] = value;
    return { ...row };
  };

  const processRowUpdate = React.useCallback(
    (key, newRow) => {
      let rows = [...comData.get(key)];

      let idx = rows.findIndex((obj) => obj?.dtime === newRow.dtime);
      rows[idx] = newRow;
      comData.set(key, rows);
      setComData(comData);

      if (!editedComData.has(key)) {
        editedComData.set(key, []);
      }
      editedComData.get(key).push(newRow.dtime);
      setEditComData(editedComData);

      return newRow;
    },
    [comData, editedComData]
  );

  //----------------------
  const micolumns = [
    {
      field: "dtime",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Sesión de negociación",
      width: 120,
      editable: false,
    }, // groups:

    {
      field: "bid_volume",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Volumen total (MWh)",
      width: 120,
      editable: true,
    }, // groups: OFERTAS / COMPRA /

    {
      field: "bid_price_mean",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Precio medio ponderado",
      width: 120,
      editable: true,
    }, // groups: OFERTAS / COMPRA /

    {
      field: "ask_volume",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Volumen total (MWh)",
      width: 120,
      editable: true,
    }, // groups: OFERTAS / VENTA/

    {
      field: "ask_price_mean",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Volumen total (MWh)",
      width: 120,
      editable: true,
    }, // groups: OFERTAS / VENTA/

    {
      field: "maxspread",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Máxima separación de precios permitida (€/MWh)",
      width: 140,
      editable: true,
    }, // groups: OFERTAS /

    {
      field: "spread_vwap",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Media diaria separación de precios (€/MWh)",
      width: 120,
      editable: true,
      valueSetter: valSetter,
    }, // groups: OFERTAS /

    {
      field: "trade_buy",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Número de transacciones",
      width: 120,
      editable: true,
    }, // groups: TRANSACCIONES BAJO CUMPLIMIENTO / COMPRA /

    {
      field: "trade_volume_buy",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Volumen total (MWh)",
      width: 120,
      editable: true,
    }, // groups: TRANSACCIONES BAJO CUMPLIMIENTO / COMPRA /

    {
      field: "trade_price_buy_mean",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Precio medio ponderado",
      width: 120,
      editable: true,
    }, // groups: TRANSACCIONES BAJO CUMPLIMIENTO / COMPRA /

    {
      field: "trade_sell",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Número de transacciones",
      width: 120,
      editable: true,
    }, // groups: TRANSACCIONES BAJO CUMPLIMIENTO / VENTA/

    {
      field: "trade_volume_sell",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Volumen total (MWh)",
      width: 120,
      editable: true,
    }, // groups: TRANSACCIONES BAJO CUMPLIMIENTO / VENTA /

    {
      field: "trade_price_sell_mean",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Precio medio ponderado",
      width: 120,
      editable: true,
    }, // groups: TRANSACCIONES BAJO CUMPLIMIENTO / VENTA/

    {
      field: "trade_volume_fulfilled",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Volumen total bajo cumplimiento (MWh)",
      width: 140,
      editable: true,
    }, // groups: CUMPLIMIENTO /

    {
      field: "maxvolume_daily",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "VDMC (MWh)",
      width: 120,
      editable: true,
    }, // groups: CUMPLIMIENTO /

    {
      field: "maxsize_time",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Hora a la que se alcanza el VDMC",
      width: 120,
      editable: true,
    }, // groups: CUMPLIMIENTO /

    {
      field: "slottime",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Franjas horarias establecidas",
      width: 120,
      editable: true,
    }, // groups: CUMPLIMIENTO /

    {
      field: "slottime_evaluate",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Franjas horarias a evaluar",
      width: 120,
      editable: true,
    }, // groups: CUMPLIMIENTO /

    {
      field: "time_evaluated",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Minutos totales a evaluar",
      valueGetter: intervalCellRender,
      valueSetter: intervalSetter,
      width: 120,
      editable: true,
      type: "number",
    }, // groups: CUMPLIMIENTO /

    {
      field: "time_exonerated",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Minutos exención",
      valueGetter: intervalCellRender,
      valueSetter: intervalSetter,
      width: 120,
      editable: true,
      type: "number",
    }, // groups: CUMPLIMIENTO /

    {
      field: "time_fm",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Minutos en FM",
      valueGetter: intervalCellRender,
      valueSetter: intervalSetter,
      width: 120,
      editable: true,
      type: "number",
    }, // groups: CUMPLIMIENTO /

    {
      field: "time_fm_fulfilled",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Minutos en FM cumplidos",
      valueGetter: intervalCellRender,
      valueSetter: intervalSetter,
      width: 120,
      editable: true,
      type: "number",
    }, // groups: CUMPLIMIENTO /

    {
      field: "time_sm",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Minutos en SM",
      valueGetter: intervalCellRender,
      valueSetter: intervalSetter,
      width: 120,
      editable: true,
      type: "number",
    }, // groups: CUMPLIMIENTO /

    {
      field: "time_sm_fulfilled",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Minutos en SM cumplidos",
      valueGetter: intervalCellRender,
      valueSetter: intervalSetter,
      width: 120,
      editable: true,
      type: "number",
    }, // groups: CUMPLIMIENTO /

    {
      field: "time_nc_fulfilled",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Minutos en condiciones normales cumplidos",
      valueGetter: intervalCellRender,
      valueSetter: intervalSetter,
      width: 160,
      editable: true,
      type: "number",
    }, // groups: CUMPLIMIENTO /

    {
      field: "time_fulfilled",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Minutos cumplidos",
      valueGetter: intervalCellRender,
      valueSetter: intervalSetter,
      width: 120,
      editable: true,
      type: "number",
    }, // groups: CUMPLIMIENTO /

    {
      field: "time_not_fulfilled",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "Minutos no cumplidos",
      valueGetter: intervalCellRender,
      valueSetter: intervalSetter,
      width: 120,
      editable: true,
      type: "number",
    }, // groups: CUMPLIMIENTO /

    {
      field: "recommendation",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "RECOMENDACIÓN",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: ["OK", "NOOK", "EXO", "EXC"],
    }, // groups:

    {
      field: "evaluation",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      align: "center",
      headerAlign: "center",
      headerName: "EVALUACIÓN",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: ["OK", "NOOK", "EXO", "EXC"],
    }, // groups:
  ];

  const columnGroupingModel = [
    {
      groupId: "OFERTAS",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2 content-center",
      description: "",
      children: [
        {
          groupId: "COMPRA",
          headerAlign: "center",
          description: "",
          children: [{ field: "bid_volume" }, { field: "bid_price_mean" }],
        },
        {
          groupId: "VENTA",
          headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
          children: [{ field: "ask_volume" }, { field: "ask_price_mean" }],
        },
        { field: "maxspread" },
        {
          field: "spread_vwap",
          headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        },
      ],
    },
    {
      groupId: "TRANSACCIONES BAJO CUMPLIMIENTO",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
      children: [
        {
          groupId: "COMPRA",
          headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
          children: [
            { field: "trade_buy" },
            { field: "trade_volume_buy" },
            { field: "trade_price_buy_mean" },
          ],
        },
        {
          groupId: "VENTA",
          headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
          children: [
            { field: "trade_sell" },
            { field: "trade_volume_sell" },
            { field: "trade_price_sell_mean" },
          ],
        },
      ],
    },
    {
      groupId: "CUMPLIMIENTO",
      headerClassName: "bg-cyan-500 text-white sticky top-0 p-2 content-center",
      description: "",
      children: [
        { field: "trade_volume_fulfilled" },
        { field: "maxvolume_daily" },
        { field: "maxsize_time" },
        { field: "slottime" },
        { field: "slottime_evaluate" },
        { field: "time_evaluated" },
        { field: "time_exonerated" },
        { field: "time_fm" },
        { field: "time_fm_fulfilled" },
        { field: "time_sm" },
        { field: "time_sm_fulfilled" },
        { field: "time_nc_fulfilled" },
        { field: "time_fulfilled" },
        { field: "time_not_fulfilled" },
      ],
    },
  ];

  return (
    <div className="max-w-screen-lg min-w-full place-content-center">
      <div className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900">
        <h3>Informe de Cumplimiento</h3>
      </div>
      <LinkedDropdowns />

      <button
        className={`bg-purple-300 rounded-lg py-2 px-10 text-white mt-10 mx-10 
            ${
              !selMonth ? "cursor-not-allowed opacity-50" : "hover:bg-purple-400"
            }`} // Conditionally apply cursor style and other visual effects
        disabled={!selMonth}
        onClick={() => handleGenerarSpreads()}
      >
        Generar Spreads
      </button>

      <button
        className={`bg-violet-300 rounded-lg py-2 px-10 text-white mt-10 mx-10 
            ${
              !selMonth ? "cursor-not-allowed opacity-50" : "hover:bg-violet-400"
            }`} // Conditionally apply cursor style and other visual effects
        disabled={!selMonth}
        onClick={() => handleEjecutarCumplimiento()}
      >
        Ejecutar Cumplimiento
      </button>

      <button
        className={`bg-indigo-300 rounded-lg py-2 px-10 text-white mt-10 mx-10 
            ${
              !selMonth ? "cursor-not-allowed opacity-50" : "hover:bg-indigo-400"
            }`} // Conditionally apply cursor style and other visual effects
        disabled={!selMonth}
        onClick={() => saveChanges()}
      >
        Guardar Cambios
      </button>

      <button
        className={`bg-blue-300 rounded-lg py-2 px-10 text-white mt-10 mx-10 
            ${
              !selMonth ? "cursor-not-allowed opacity-50" : "hover:bg-blue-400"
            }`} // Conditionally apply cursor style and other visual effects
        disabled={!selMonth}
        onClick={() => handleGenerarInformes()}
      >
        Generar Informes
      </button>
      <pre
        className={`py-2 px-5 text-white mt-2 mx-auto max-w-fit rounded-3xl ${
          updating ? "bg-cyan-500/20 " : ""
        }`}
      >
        {updating}
      </pre>
      <div className="mt-100 mx-auto min-w-full max-w-fit overflow-x-auto"></div>
      <div className="mt-100 min-w-full max-w-fit overflow-x-auto ">
        {[...comData.entries()].map(([key, val]) => (
          <div key={key} className="">
            <p className="h2 mt-4 mb-2 pt-5 pb-3 font-semibold text-lg">
              {key} - {selMonth}
            </p>
            <ThemeProvider theme={theme}>
              <DataGrid
                getRowId={(row) => `${row.product}_${row.dtime}`}
                processRowUpdate={(e) => processRowUpdate(key, e)}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                getRowClassName={(params) =>
                  `${
                    params.indexRelativeToCurrentPage % 2 === 0
                      ? ""
                      : "bg-blue-100"
                  }`
                }
                rows={val}
                columns={micolumns}
                columnGroupingModel={columnGroupingModel}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                sx={{
                  maxHeight: "500",
                  width: "auto", // Make the DataGrid width auto
                  minWidth: "10px", // Ensure it takes up full width if container is narrower
                  columnHeaderTitle: {
                    textAlign: "center",
                    headerAlign: "center",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    overflowY: "auto", // Enable vertical scrolling for rows
                    maxHeight: "80vh", // Limit height of the rows area (adjust as needed)
                  },
                  "& .MuiDataGrid-scrollbar--horizontal": {
                    left: 0,
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal",
                    textAlign: "center",
                    headerAlign: "center",
                  },
                }}
              />
            </ThemeProvider>

            <div className="min-h-24"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ComplianceReport;
