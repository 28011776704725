import React from "react";
import { useState, useEffect } from "react";
import {
  getConditionsData,
  getPrevConditionsData,
  setConditionsData,
} from "../services/user.service";

import {
  dateMonthValueFormatter,
  dateValueFormatter,
  dateValueSetter,
  dtimeValueFormatter,
  dtimeValueGetter,
  dtimeValueSetter,
  intervalCellRender,
  intervalSetter,
} from "../functions/time.function";

import { useLocation } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { esES } from "@mui/x-data-grid/locales";
import { esES as pickersEsES } from "@mui/x-date-pickers/locales";
import { esES as coreEsES } from "@mui/material/locale";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBan } from "@fortawesome/free-solid-svg-icons";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import moment from "moment/moment";
import "moment/locale/es";
moment.locale("es");

const rowKeys = {
  mmcompanies: ["dtime", "market_maker", "id"],
  mmconditions: ["dtime", "market_maker", "product", "id"],
  mmspreads: ["dtime", "market_maker", "product", "start_level", "id"],
  mmexonerationdays: ["dtime", "market_maker", "product", "id"],
  marketconditions: ["dtime", "product", "id"],
};

function getRowKey(tabkey, row) {
  return rowKeys[tabkey].map((pk) => row[pk]).join("🦕");
}

function delR(oldRows, tabName, id) {
  let nuData = { ...oldRows };

  nuData[tabName] = nuData[tabName].filter((row) => row.id !== id);

  return nuData;
}

function addR(oldRows, tabName, month) {
  let nuData = { ...oldRows };
  let nuidx =
    nuData[tabName].length > 0
      ? Math.max(...nuData[tabName].map((obj) => obj.id)) + 1
      : 0;
  let pritem = { dtime: month, isNew: true, id: nuidx };
  if (tabName === "marketconditions") {
    pritem["market_conditions"] = "";
  }

  nuData[tabName] = [...nuData[tabName], pritem];

  return nuData;
}

function CondTable(data) {
  let tabName = data.tablekey;
  let tabdata = data.fulldata[tabName];
  let setCondData = data.setter;
  let nuDtime = moment(data.month, "MMMM YYYY").format("yyyy-MM-DD");
  let condData = data.fulldata;

  const deleteEntry = React.useCallback(
    (params, setCondData, tabName) => () => {
      setTimeout(() => {
        setCondData((oldRows) => delR(oldRows, tabName, params.row.id));
      });
    },
    []
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log({ children: error.message, severity: "error" });
  }, []);

  const processRowUpdate = React.useCallback(
    async (nuRow, oldRow, condData, setCondData, tabName) => {
      let idx = condData[tabName].findIndex((obj) => obj?.id === nuRow.id);
      let nuData = { ...condData };
      let nutab = [...condData[tabName]];

      nutab[idx] = { ...nuRow };
      nuData[tabName] = nutab;

      setCondData(nuData);

      return { ...nuRow };
    },
    []
  );

  const micolumns = {
    mmcompanies: [
      {
        field: "dtime",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Mes",
        width: 140,
        editable: false,
        valueFormatter: dateMonthValueFormatter,
      },

      {
        field: "market_maker",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Código CM",
        width: 120,
        editable: true,
      },

      {
        field: "company_name",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Denominación social",
        width: 300,
        editable: true,
      },
    ],
    mmconditions: [
      {
        field: "dtime",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Inicio condiciones",
        width: 140,
        editable: true,
        type: "date",
        valueGetter: dtimeValueGetter,
        valueFormatter: dateValueFormatter,
        valueSetter: dateValueSetter,
      },
      {
        field: "market_maker",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Código CM",
        width: 120,
        editable: true,
      },
      {
        field: "product",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Producto",
        width: 120,
        editable: true,
      },
      {
        field: "market_maker_type",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Tipo de CM",
        width: 120,
        editable: true,
      },
      {
        field: "product_type",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Tipo de producto",
        width: 120,
        editable: true,
      },
      {
        field: "minsize",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Cantidad mínima ofertada (MWh/d)",
        width: 120,
        editable: true,
      },
      {
        field: "maxspread",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Máxima separación de precios permitida",
        width: 120,
        editable: true,
      },
      {
        field: "maxspread_sm",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Máxima separación durante SM",
        width: 120,
        editable: true,
      },
      {
        field: "maxspread_fm",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Máxima separación durante FM",
        width: 120,
        editable: true,
      },
      {
        field: "spread_vtp_percentage",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Porcentaje usado para calcular el spread porcentual",
        width: 180,
        editable: true,
      },
      {
        field: "minspread_vtp",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Mínimo spread porcentual",
        width: 120,
        editable: true,
      },
      {
        field: "maxspread_vtp",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Máximo spread porcentual",
        width: 120,
        editable: true,
      },
      {
        field: "maxvolume_daily",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Volumen máximo a casar (MWh)",
        width: 120,
        editable: true,
      },
      {
        field: "maxsize_daily",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Cantidad máxima a casar (MWh/d)",
        width: 120,
        editable: true,
      },
      {
        field: "continue_after_vdmc",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Medir volumen tras VDMC",
        width: 120,
        editable: true,
        type: "boolean",
        valueOptions: [true, false],
      },
      {
        field: "replacement_time",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Tiempo de reposición",
        width: 120,
        editable: true,
        type: "number",
        valueGetter: intervalCellRender,
        valueSetter: intervalSetter,
      },
      {
        field: "exoneration_time",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Porcentaje de exoneración",
        width: 120,
        editable: true,
      },
      {
        field: "slottime",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Franjas de cumplimiento",
        width: 190,
        editable: true,
      },
      {
        field: "import",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Importe mensual",
        width: 120,
        editable: true,
      },
      {
        field: "increment_after_maxsize_daily",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Incremento tras VDMC",
        width: 120,
        editable: true,
      },
      {
        field: "mindays",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Mínimo días para contar días de exoneración",
        width: 120,
        editable: true,
      },
      {
        field: "import_fm",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Incremento por actividad durante FM",
        width: 120,
        editable: true,
      },
    ],
    mmspreads: [
      {
        field: "dtime",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Mes",
        width: 140,
        editable: false,
        valueFormatter: dateMonthValueFormatter,
      },
      {
        field: "market_maker",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Código CM",
        width: 100,
        editable: true,
      },
      {
        field: "product",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Producto",
        width: 120,
        editable: true,
      },
      {
        field: "start_level",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Inicio rango de precios",
        width: 120,
        editable: true,
      },
      {
        field: "maxspread_sm",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Máxima separación durante SM",
        width: 120,
        editable: true,
      },
    ],
    mmexonerationdays: [
      {
        field: "dtime",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Día de exoneración",
        width: 120,
        editable: true,
        type: "date",
        valueGetter: dtimeValueGetter,
        valueFormatter: dateValueFormatter,
        valueSetter: dateValueSetter,
      },
      {
        field: "market_maker",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Código CM",
        width: 120,
        editable: true,
      },
      {
        field: "product",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Producto",
        width: 120,
        editable: true,
      },
    ],
    marketconditions: [
      {
        field: "dtime",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Inicio escenario",
        width: 160,
        editable: true,
        type: "dateTime",
        valueGetter: dtimeValueGetter,
        valueFormatter: dtimeValueFormatter,
        valueSetter: dtimeValueSetter,
      },
      {
        field: "product",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Producto",
        width: 120,
        editable: true,
      },
      {
        field: "market_conditions",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Escenario detectado",
        width: 120,
        editable: true,
        type: "singleSelect",
        valueOptions: ["SM", "FM"],
      },
      {
        field: "period_end",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Fin escenario",
        width: 160,
        editable: true,
        type: "dateTime",
        valueGetter: dtimeValueGetter,
        valueFormatter: dtimeValueFormatter,
        valueSetter: dtimeValueSetter,
      },
      {
        field: "start_level",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Nivel de precios",
        width: 120,
        editable: true,
      },
    ],
    actions: [
      {
        field: "actions",
        type: "actions",
        width: 20,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon={faBan} />}
            label="Delete"
            title="eliminar fila"
            onClick={deleteEntry(params, setCondData, tabName)}
          />,
        ],
        renderHeader: () => (
          <div>
            <button
              title="Añadir fila"
              onClick={() => {
                setCondData((oldRows) => addR(oldRows, tabName, nuDtime));
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        ),
      },
    ],
  };

  let dgminwidth = tabName === "mmconditions" ? "10px" : "auto";
  let dgheadersize = tabName === "mmconditions" ? 80 : 52;
  return (
    <div
      className={`inline-block overflow-x-auto min-w-1 ${
        tabName === "mmconditions" ? "max-w-full w-full " : ""
      }`}
    >
      <LocalizationProvider
        localeText={
          pickersEsES.components.MuiLocalizationProvider.defaultProps.localeText
        }
        dateAdapter={AdapterMoment}
        adapterLocale={"es"}
      >
        <DataGrid
          getRowId={(row) => getRowKey(tabName, row)}
          getRowClassName={(params) =>
            `${
              params.indexRelativeToCurrentPage % 2 === 0 ? "" : "bg-blue-100"
            }`
          }
          processRowUpdate={(newRow, oldRow) =>
            processRowUpdate(newRow, oldRow, condData, setCondData, tabName)
          }
          onProcessRowUpdateError={handleProcessRowUpdateError}
          rows={tabdata}
          columns={[...micolumns.actions, ...micolumns[tabName]]}
          autoHeight={tabName !== "mmconditions" || !tabdata[0]}
          hideFooterPagination
          hideFooterSelectedRowCount
          hideFooter
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rowLength={500}
          columnHeaderHeight={dgheadersize}
          sx={{
            maxHeight: "500",
            width: "auto", // Make the DataGrid width auto
            minWidth: dgminwidth, // Ensure it takes up full width if container is narrower
            "& .MuiDataGrid-virtualScroller": {
              overflowY: "auto", // Enable vertical scrolling for rows
              maxHeight: "80vh", // Limit height of the rows area (adjust as needed)
            },
            "& .MuiDataGrid-scrollbar--horizontal": {
              left: 0,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}

const theme = createTheme(
  {
    mixins: {
      MuiDataGrid: {
        containerBackground: "#06b6d4",
        columnHeaderTitle: {
          color: "white", // Specific targeting
        },
      },
    },
  },
  esES,
  pickersEsES,
  coreEsES
);

function MMConditions() {
  const [condData, setCondData] = useState({});
  const [prevData, setPrevData] = useState({});
  const [origCondData, setOrigCondData] = useState(new Map());
  const [updating, setUpdating] = useState("");
  const [selMonth, setSelmonth] = useState(moment().format("MMMM YYYY"));
  const [prevMonth, setPrevMonth] = useState("");

  const tablenames = {
    mmcompanies: "Nombres de los CM",
    mmconditions: "Condiciones de los CM",
    mmspreads: "Spreads durante SM",
    mmexonerationdays: "Días de exoneración",
    marketconditions: "Condiciones de mercado",
  };

  let location = useLocation();

  function compareObjectsWithoutId(obj1, obj2) {
    const obj1Keys = Object.keys(obj1).filter((key) => key !== "id");
    const obj2Keys = Object.keys(obj2).filter((key) => key !== "id");

    return (
      obj1Keys.every((key) => obj1[key] === obj2[key]) &&
      obj2Keys.every((key) => obj2[key] === obj1[key])
    );
  }

  function diffData(origData, data, monthstr) {
    const ymonth = moment(monthstr, "MMMM YYYY").format("yyyy-MM");
    const changes = {};
    const validatingerrors = [];

    Object.keys(data).forEach((tabName) => {
      const origArray = origData[tabName];
      const newArray = data[tabName];

      // Compare objects excluding 'id'
      const upsertEntries = newArray.filter((newItem) => {
        return !origArray.some((origItem) =>
          compareObjectsWithoutId(newItem, origItem)
        );
      });

      // Find deleted entries that are in origData but not in the newData
      const deletedEntries = origArray.filter((origItem) => {
        return !newArray.some((newItem) =>
          compareObjectsWithoutId(newItem, origItem)
        );
      });

      const wrongTimeEntries = upsertEntries.filter(
        (itm) => !itm.dtime.startsWith(ymonth)
      ).length;
      if (wrongTimeEntries > 0) {
        validatingerrors.push(
          `Para '${tablenames[tabName]}' hay ${wrongTimeEntries} fila${
            wrongTimeEntries > 1 ? "s" : ""
          } con el tiempo correspondiente a otro mes.`
        );
      }

      changes[tabName] = {
        upsertEntries, // Combine new and updated entries
        deletedEntries,
      };
    });

    return [changes, validatingerrors];
  }

  const refresh = () => {
    getCondData(moment(selMonth, "MMMM YYYY").format("yyyy-MM-DD"));
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getCondData = (month) => {
    setPrevMonth("");
    getConditionsData(month)
      .then((res) => {
        let conditionsData = JSON.parse(res.data);

        Object.entries(conditionsData).forEach(([k, v]) => {
          v.forEach((entry, idx) => {
            entry.id = idx;
          });
        });
        setCondData(conditionsData);
        setOrigCondData(structuredClone(conditionsData));
      })
      .catch((err) => console.log(err));

    getPrevConditionsData(month)
      .then((res) => {
        let conditionsData = JSON.parse(res.data);
        if (conditionsData.mmconditions[0]?.dtime) {
          setPrevMonth(
            moment(conditionsData.mmconditions[0]?.dtime).format("MMMM YYYY")
          );
          setPrevData(conditionsData);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleMonthChange = (month) => {
    month.startOf("month");
    setSelmonth(month.format("MMMM YYYY"));
    getCondData(month.format("yyyy-MM-DD"));
  };

  const copyPrevData = () => {
    let nuData = { ...condData };
    const nudtime = moment(selMonth, "MMMM YYYY").format("yyyy-MM-DD");
    ["mmcompanies", "mmconditions", "mmspreads"].forEach((tab) => {
      nuData[tab] = prevData[tab].map((item, idx) => {
        let pritem = {
          ...item,
          dtime: nudtime, // Replace all dtime values with nudtime
          id: idx,
        };
        return pritem;
      });
    });
    setCondData(nuData);
  };

  const saveChanges = () => {
    let [stuffchanged, validatingerrors] = diffData(
      origCondData,
      condData,
      selMonth
    );

    if (validatingerrors.length > 0) {
      const errorstr = `Hemos encontrado errores.\n${validatingerrors.join(
        "\n"
      )}`;
      console.log(errorstr);
      setUpdating(errorstr);
    } else {
      let updateNeeded = Object.values(stuffchanged).some((changeSet) => {
        return (
          changeSet.upsertEntries.length > 0 ||
          changeSet.deletedEntries.length > 0
        );
      });

      if (updateNeeded) {
        setUpdating(
          `Actualizando condiciones de cumplimiento a las ${moment().format(
            "HH:mm:SS"
          )}.`
        );
        setConditionsData(stuffchanged)
          .then((response) => {
            setUpdating(
              `Respuesta recibida a las ${moment().format("HH:mm:SS")}:\n${
                response.data
              }`
            );
            refresh();
          })
          .catch((error) => {
            console.log(
              error,
              error?.response?.data ? error?.response?.data : ""
            );
            setUpdating(
              `Error recibido a las ${moment().format(
                "HH:mm:SS"
              )} mientras se actualizaba:\n${error}${
                error?.response?.data
                  ? `\nDetalle del error:${error?.response?.data}`
                  : ""
              }`
            );
            // refresh();
          });
      } else {
        setUpdating("Nada que actualizar");
      }
    }
  };

  //----------------------

  return (
    <div id="MMCnd" className="inline-block max-w-full">
      <div className="font-medium leading-tight text-3xl mt-0 mb-7 text-indigo-900">
        <h3>Mantenimiento de Creadores de Mercado</h3>
      </div>
      <LocalizationProvider
        localeText={
          pickersEsES.components.MuiLocalizationProvider.defaultProps.localeText
        }
        dateAdapter={AdapterMoment}
        adapterLocale={"es"}
      >
        <div id="picker">
          <DatePicker
            label="Mes de análisis"
            views={["year", "month"]} // Set the views to year and month
            value={moment(selMonth, "MMMM YYYY")}
            onChange={handleMonthChange}
          />
        </div>
        <button
          className="bg-indigo-300 rounded-lg py-2 px-10 text-white mt-10 mx-10 hover:bg-indigo-400"
          onClick={() => saveChanges()}
        >
          Guardar Cambios
        </button>

        <button
          title={
            "Rellena las tablas con la información del último mes (anterior) para el que haya datos." +
            `\n${
              !prevMonth
                ? "No hay datos para los meses anteriores."
                : "Se usará " + prevMonth
            }.`
          }
          className={`bg-blue-300 rounded-lg py-2 px-10 text-white mt-10 mx-10 
            ${
              !prevMonth ? "cursor-not-allowed opacity-50" : "hover:bg-blue-400"
            }`} // Conditionally apply cursor style and other visual effects
          onClick={() => copyPrevData()}
          disabled={!prevMonth}
        >
          Heredar condiciones {!prevMonth ? "anteriores" : `de ${prevMonth}`}
        </button>
        <pre
          className={`py-2 px-5 text-white mt-2 mx-auto max-w-fit rounded-3xl ${
            updating ? "bg-cyan-500/20 " : ""
          }`}
        >
          {updating}
        </pre>
        <div className="">
          {Object.keys(condData).map((tabkey) => (
            <div key={tabkey}>
              <p className="h2 mt-4 mb-2 pt-5 pb-3 font-semibold text-lg">
                {tablenames[tabkey]} - {selMonth}
              </p>
              <ThemeProvider theme={theme}>
                <CondTable
                  tablekey={tabkey}
                  setter={setCondData}
                  fulldata={condData}
                  month={selMonth}
                />
              </ThemeProvider>
              <div className="min-h-24"></div>
            </div>
          ))}
        </div>
      </LocalizationProvider>
    </div>
  );
}

export default MMConditions;
